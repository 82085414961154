// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-activiteiten-jsx": () => import("./../../../src/pages/activiteiten.jsx" /* webpackChunkName: "component---src-pages-activiteiten-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-dojo-jsx": () => import("./../../../src/pages/dojo.jsx" /* webpackChunkName: "component---src-pages-dojo-jsx" */),
  "component---src-pages-programmas-index-jsx": () => import("./../../../src/pages/programmas/index.jsx" /* webpackChunkName: "component---src-pages-programmas-index-jsx" */),
  "component---src-pages-training-in-het-park-jsx": () => import("./../../../src/pages/training-in-het-park.jsx" /* webpackChunkName: "component---src-pages-training-in-het-park-jsx" */),
  "component---src-pages-trainingen-jsx": () => import("./../../../src/pages/trainingen.jsx" /* webpackChunkName: "component---src-pages-trainingen-jsx" */),
  "component---src-templates-card-page-tsx": () => import("./../../../src/templates/card-page.tsx" /* webpackChunkName: "component---src-templates-card-page-tsx" */),
  "component---src-templates-default-page-tsx": () => import("./../../../src/templates/default-page.tsx" /* webpackChunkName: "component---src-templates-default-page-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */)
}

